.bottom-right-btns[data-v-724f014f] {
  display: flex;
  gap: 20px;
  align-items: center;
  /* padding-left: 20px; */
}
.bottom-right-btns button[data-v-724f014f] {
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 0.5em 2.5em;
  border: 0;
  background-color: var(--main-clr);
}
.dropdown[data-v-724f014f] {
  position: absolute;
  display: flex;
  width: 160px;
  /* height: 88px; */
  top: 0;
  transform: translateY(-100%);
  padding: 12px 0px 16px 10px;
  flex-direction: column;

  gap: 20px;
  border-radius: 8px;
  background-color: var(--lighter-grey-clr);
}
.dropdown button[data-v-724f014f] {
  font-size: 16px;
  text-align: left;
  background-color: transparent;
  font-style: normal;
  cursor: pointer;
  color: var(--text-clr);
  font-weight: 400;
  border: none;
  outline: 0;
}
.btn-and-dropdown-container[data-v-724f014f] {
  position: relative;
}
.bottom-flex[data-v-724f014f] {
  background: var(--white-clr);
  position: fixed;
  align-items: center;
  bottom: 0%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  flex-direction: row;
  padding-right: 20px;
  box-shadow: 0px -5px 25px 0px rgba(0, 0, 0, 0.15);
  border-top: 1px solid var(--lighter-grey-clr);
}
.new-document-btn[data-v-724f014f] {
  border-radius: 8px;
  display: flex;
  font-size: 16px;
  gap: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 0.5em 2.5em;
  border: 0;
  color: var(--main-clr);
  border: 1px solid var(--main-clr);
  background-color: transparent;
}
