.automation-manager-main-container[data-v-b45d1fc4] {
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
  
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.tool-kit[data-v-b45d1fc4] {
    background-color: var(--white-clr);
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    width: 315px;
    transition: all 0.2s ease-in;
}
.page-subheader[data-v-b45d1fc4] {
    font-size: 14px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: var(--text-clr);
}
.page-subheader button[data-v-b45d1fc4] {
    background: transparent;
    border: none;
    cursor: pointer;
    /* color: var(--text-clr); */
}
.flex-folders-files[data-v-b45d1fc4] {
    display: flex;
    flex-direction: column;
}
.folders-container[data-v-b45d1fc4] {
    padding-left: 15px;
    /* padding-top: 5px; */
    padding-right: 60px;
    padding-bottom: 38px;
}
.close-comment-icon[data-v-b45d1fc4] {
    padding: 0;
    cursor: pointer;
    /* margin-right: 25px; */
    /* display; */
    background-color: transparent;
    border: none;
}
.bordered-icons[data-v-b45d1fc4] {
    border: 1px solid var(--text-clr);
    padding: 0.2em 0.5em;
    border-radius: 50%;
}
.search-container[data-v-b45d1fc4] {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 14px;
}