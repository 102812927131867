circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.spinner {
    cursor: default;
}
.spinner circle {
  animation: rotate 2s linear infinite;
  cursor: default;
}
.progress-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress-label {
    text-transform: capitalize;
}
.fade {
    animation: fade 4s linear infinite
}
@keyframes rotate {
  0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
