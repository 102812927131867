.app[data-v-7d88bc5a] {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* background-image: linear-gradient(to bottom, #dbf9fd, #edfafb 59%); */
}
@media only screen and (max-width: 600px) {
.new-chat-flex[data-v-7d88bc5a] {
        margin-top: 40px !important;
}
.upper-part-container[data-v-7d88bc5a] {
        padding: 10px !important;
}
.app[data-v-7d88bc5a] {
        flex-direction: column;
}
.main-page-container[data-v-7d88bc5a] {
        flex-direction: column;
        height: calc(100vh - 90px);
}
.mobile-hide[data-v-7d88bc5a] {
        display: none;
}
}
.main-page-container[data-v-7d88bc5a] {
    display: flex;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
}
.submit-strip[data-v-7d88bc5a] {
    align-items: center;
    position: absolute;
    bottom: 0%;
    padding-left: 20px;
    width: 100%;
    height: 60px;
    display: flex;
    background-color: var(--white-clr);
}
.submit-btn[data-v-7d88bc5a] {
    border-radius: 8px;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border: none;
    font-family: Assistant;
    background-color: var(--main-clr);
    width: 107px;
    height: 32px;
    padding: 6px 22px 6px 23px;
    justify-content: center;
    align-items: center;
}
.new-chat-btn[data-v-7d88bc5a] {
    padding: 0.5em 1em;
    display: flex;
    background-color: var(--main-clr);
    color: #131313;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
}
.canvas-chat-container[data-v-7d88bc5a] {
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 20px;
    height: 2rem;
    gap: 21px;
    flex-shrink: 0;
    border-radius: 7px;
    background-color: var(--white-clr);
}
.canvas-chat-btn[data-v-7d88bc5a] {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    height: 1.5rem;
    margin-top: 0.25rem;
    color: black;
    width: 6rem;
}
.canvas-chat-btn.active-mode[data-v-7d88bc5a] {
    border-radius: 5px;
    background: #e3e3e3;
    background: var(--Light-Mode-Light-Grey, #e3e3e3);
}
.page-subheader[data-v-7d88bc5a] {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-clr);
    opacity: 0.5;
}
.new-chat-flex[data-v-7d88bc5a] {
    display: flex;
    margin-top: 20px;

    justify-content: space-between;
}
.main-content-container[data-v-7d88bc5a] {
    flex-grow: 1;
    /* padding-top: 20px;
   padding-right:40px;
   padding-bottom: 20px;
   padding-left: 15px; */
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.main-content-container.collapsed-content[data-v-7d88bc5a] {
    flex: 0 0 60px;
    min-width: 60px;
    width: 60px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: white;
    border-right: 1px solid #eaeaea;
    position: relative;
    visibility: collapse;
}
.main-content-container.collapsed-content .content-toggle-nav[data-v-7d88bc5a] {
    visibility: visible !important;
}
.content-toggle-nav[data-v-7d88bc5a] {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}
.collapsed-content > .content-toggle-nav[data-v-7d88bc5a] {
    display: flex;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #eaeaea;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}
.upper-part-container[data-v-7d88bc5a] {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 36px;
    padding-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}
.shadowed[data-v-7d88bc5a] {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}
.preview-bg[data-v-7d88bc5a] {
    background: rgba(0, 0, 0, 0.15);
    height: 100%;
    position: absolute;
    top: 0%;
    pointer-events: none;
    left: 0%;
    z-index: 1;
    overflow-x: hidden;
    width: 100%;
}
.btns-container[data-v-7d88bc5a] {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.download-chat-container[data-v-7d88bc5a] {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--secondary-clr);
    cursor: pointer;
}
