.icon-name-container[data-v-b5897dfa] {
  display: flex;
  gap: 4px;
  align-items: center;
  /* width:fit-content; */
}
.icon-name-container p[data-v-b5897dfa] {
  font-size: 18px;
  font-family: Assistant;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  /* width: 77%; */
}
td[data-v-b5897dfa] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  padding: 13px 5px;
}
tr[data-v-b5897dfa] {
  width: 100%;
}
.checkbox[data-v-b5897dfa] {
  display: none;
}
