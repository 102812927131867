.canvas-panel {
    flex: 0 0 400px;
    min-width: 300px;
    max-width: 1200px;
    height: 100%;
    background-color: white;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    position: relative;
    animation: coolIn 0.7s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
    transform-origin: right center;
}

@keyframes coolIn {
    0% {
        transform: translateX(40px) scale(0.96);
        opacity: 0;
        box-shadow: -5px 0 30px rgba(0, 0, 0, 0);
    }

    100% {
        transform: translateX(0) scale(1);
        opacity: 1;
        box-shadow: -5px 0 30px rgba(0, 0, 0, 0.05);
    }
}

.canvas-panel.main-collapsed {
    flex: 1 1;
    max-width: none;
}

.canvas-panel.resizing {
    -webkit-user-select: none;
            user-select: none;
}

.canvas-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.canvas-header {
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.canvas-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.canvas-header .canvas-btns {
    display: flex;
    align-items: center;
    gap: 12px;
}

.new-canvas-btn {
    padding: 0.5em 1em;
    display: flex;
    background-color: var(--main-clr);
    color: #131313;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
}

.canvas-header svg {
    width: 20px;
    height: 20px;
}

.canvas-content {
    flex: 1 1;
    overflow: auto;
    padding: 24px;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f1f1f1;
}

.canvas-content::-webkit-scrollbar {
    width: 8px;
}

.canvas-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.canvas-content::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
}

.resize-handle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: transparent;
    cursor: ew-resize;
    transition: background-color 0.2s;
}

.resize-handle:hover,
.resize-handle.resizing {
    background-color: #eaeaea;
}

.editor-content {
    height: 100%;
}

@import 'highlight.js/styles/github-dark.css';

.tiptap pre {
    background: #0d0d0d;
    border-radius: 0.75rem;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 1.25rem 1.5rem;
    display: inline-block;
    min-width: 300px;
    max-width: 100%;
    white-space: pre-wrap;
    box-sizing: border-box;
    margin: 1.5rem 0;
}

.tiptap pre code {
    background: none;
    color: inherit;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    white-space: pre;
}

.tiptap select.language-select {
    background: #1d1d1d;
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 1rem;
}

.tiptap pre {
    overflow-x: auto;
    white-space: pre;
}

.tiptap pre code {
    white-space: pre;
}

.ProseMirror {
    padding: 1rem;
    min-height: calc(100% - 2rem);
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell;
    background-color: #f5f5f5;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    height: auto;
    margin-top: 15px;
    overflow: hidden;
}
.ProseMirror p {
    margin: 0.5em 0;
}

.ProseMirror h1 {
    font-size: 1.5em;
    margin: 0.67em 0;
}

.ProseMirror h2 {
    font-size: 1.3em;
    margin: 0.75em 0;
}

.ProseMirror ul,
.ProseMirror ol {
    padding-right: 2.3rem; /* For RTL */
    padding-left: 2.3rem; /* For LTR */
}

.ProseMirror ul li,
.ProseMirror ol li {
    padding-right: 0.5em; /* For RTL */
    padding-left: 0.5em; /* For LTR */
}

.ProseMirror code {
    background-color: #f1f1f1;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
}

.ProseMirror mark {
    background-color: #faf594;
}
.ProseMirror table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
}

.ProseMirror td,
.ProseMirror th {
    border: 2px solid #ced4da;
    box-sizing: border-box;
    min-width: 1em;
    padding: 3px 5px;
    position: relative;
    vertical-align: top;
}

.ProseMirror th {
    background-color: #f1f3f5;
    font-weight: bold;
    text-align: left;
}

.ProseMirror .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
}

.ProseMirror[dir='rtl'] {
    direction: rtl;
    text-align: right;
}

.ProseMirror .math-inline .katex {
    font-size: 1.6em;
}

.ProseMirror p[dir='rtl'],
.ProseMirror h1[dir='rtl'],
.ProseMirror h2[dir='rtl'],
.ProseMirror h3[dir='rtl'],
.ProseMirror h4[dir='rtl'],
.ProseMirror h5[dir='rtl'],
.ProseMirror h6[dir='rtl'] {
    text-align: right;
}

.ProseMirror p[dir='ltr'],
.ProseMirror h1[dir='ltr'],
.ProseMirror h2[dir='ltr'],
.ProseMirror h3[dir='ltr'],
.ProseMirror h4[dir='ltr'],
.ProseMirror h5[dir='ltr'],
.ProseMirror h6[dir='ltr'] {
    text-align: left;
}
