.upload-status-window {
  position: fixed;
  right: 0;
  width: 23%;
  min-width: 20%;
  height: 50px;
  background-color: var(--white-clr);
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-width: 450px;
  min-width: 250px;
}

.upload-status-window .header {
  display: flex;
  width:100%;

  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  font-weight: 600;
  /* border-bottom: 1px solid #eee; */
  cursor: grab;
  -webkit-user-select: none;
          user-select: none
}
.header:active {
  cursor: grabbing; /* Change cursor when dragging */
}
.header span {
  /* cursor: auto; */
  -webkit-user-select: none;
          user-select: none;
  margin-bottom: 4px;
  /* cursor: grab; */
}

.buttons {
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
}

.option-btn {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: auto;
}

.file-list {
  position: fixed;
  left: 0;
  width: 100%;
  min-width: 70%;

  overflow-y: auto;
  max-height: 150px;
  height: 130px;
  background-color: var(--white-clr);

  border-top: 1px solid #ccc;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
}

.file-item {
    margin: 0.4em 0;
    padding: 0 0.4em;
    max-width: 450px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-family: Assistant;
    align-items: center;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    height: 42px;
}
.icon-container {
    width: 42px;
    height: auto;
    display: flex;
    justify-content: center;
}
.icon-name-container {
  /* gap: 8px; */
  display: flex;
  max-width: 100%;
  width: 90%;
  font-family: Assistant;
  align-items: center;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon-name-container span {
  font-size: 14px;
  margin-left:4%;
}

.icon-name-container img {
  width: 14px;
  height: 14px;
  margin: auto 0
}

.loading-spinner {
  width: 15px;
  height: 15px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.status-check {
  color: green;
}

.status-icon {
  cursor: auto;
}

.status-error {
  color: red;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
