.filled-btn{
    border-radius: 8px;
    border: 0;
    /* font-weight: 600; */
    display: flex;  
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size:16px;
    font-family: 'Assistant';
    width: 140px;
    vertical-align: middle;
    /* margin-top: 10px; */
    /* display: inline-block; */
    padding: 6px 0px;
    background-color: var(--main-clr);
    color: var(--text-clr);
    cursor: pointer;
}
.filled-btn:disabled{
    opacity: 0.5;
    cursor: not-allowed;

    background-color: var(--lighter-grey-clr);
}