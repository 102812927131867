.skeleton-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.skeleton-row td {
  padding: 9.5px;
  border-bottom: 1px solid #e0e0e0;
}

.skeleton-row td:first-child {
  text-align: center;
}
