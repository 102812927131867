/* @import url('https://lib/fonts/.googleapis.com/css2?family=Inter:wght@400;700&display=swap'); */
.breadcrumbs[data-v-0f4696bf]{
    opacity: 0.5;
}
.breadcrumbs-btn[data-v-0f4696bf]{
    outline: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
}
.breadcrumbs-btn[data-v-0f4696bf]:hover{
    text-decoration: underline;
    cursor: pointer;
}
.header[data-v-0f4696bf]{
    color: var(--main-clr);
    text-align: center;
    /* font-family: Inter; */
    /* font-family: 'Inter', sans-serif; */
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.page-subheader[data-v-0f4696bf]{
    font-size: 14px;
    display: flex;
     /* justify-content: center; */
    align-items: center; 
    gap:10px;
    color: var(--text-clr);
}
.close-comment-icon[data-v-0f4696bf]{
    padding: 0;
    cursor: pointer ;
    /* margin-right: 25px; */
    background-color: transparent;
    border: none;
}
.close-comment-icon[data-v-0f4696bf]:disabled{
    pointer-events: none;
    opacity: 0.5;
    cursor:auto !important;
    cursor:initial !important;
}
.bordered-icons[data-v-0f4696bf]{
    border: 1px solid var(--text-clr);
    padding: 0.2em 0.5em;
    border-radius: 50%;
}
.submit-btn-document[data-v-0f4696bf]{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 0;
    margin-top: 10px;
    padding: 6px 10px;
    background-color: var(--main-clr);
    color: var(--text-clr);
    cursor: pointer;
}
.submit-btn-document[data-v-0f4696bf]:disabled{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0.5;
  cursor: inherit !important;
  border-radius: 8px;
  border: 0;
  margin-top: 10px;
  padding: 6px 10px;
  background-color: var(--main-clr);
  color: var(--text-clr);
  cursor: pointer;
}
.search-box-container[data-v-0f4696bf] {
  display: flex;
  flex-direction:row-reverse;
  justify-content: flex-end;
  margin-left:70%;
  margin-bottom:10px;
  /* margin-right: 30px; */
  /* padding-bottom: 20px; */
  /* position: sticky; */
  /* gap:10px; */
  /* z-index: 1; */
}
.search-input[data-v-0f4696bf] {
  font-weight: 600;
  color: var(--text-clr);
  border-radius: 8px;
  border-radius: var(--8px, 8px);
  outline: 0;
  border: 0;
  background-color: var(--lighter-grey-clr);
  width: 280px;
  height: 30px;
  padding: 8px 12px;
  padding: var(--8px, 8px) var(--12px, 12px);
  gap: 4px;
  gap: var(--4-px, 4px);
}