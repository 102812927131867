.read-more-text[data-v-47cc667b] {
  font-weight: 400;
  text-align: left; 
  white-space: pre-wrap; 
  word-wrap: break-word;
}
.read-or-hide[data-v-47cc667b] {
  color: black; 
  cursor: pointer;
  margin-left: 5px;
  -webkit-user-select: none;
          user-select: none;
  font-weight: bold;
}
.read-or-hide[data-v-47cc667b]:hover {
  text-decoration: underline;
}
