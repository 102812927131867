.breadcrumbs[data-v-6daa19c4] {
    opacity: 0.5;
    margin-left: 5px;
    margin-bottom: 3px;
    font-size: 14px; /* Adjust as needed */
}
.breadcrumbs-btn[data-v-6daa19c4] {
    outline: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    color: var(--text-clr);
}
.breadcrumbs-btn[data-v-6daa19c4]:hover {
    text-decoration: underline;
}
.current-breadcrumb[data-v-6daa19c4] {
    color: var(--text-clr);
    cursor: default;
}
  