img[data-v-e349449c]{
    width:18px;
    height: 18px;
}
.flex-files[data-v-e349449c]{
    align-items: center;
    display: flex;
    max-width: 760px;
    gap:5px;
    margin-top: 5px;
    align-items: center;
    /* margin-top: 5px; */

    flex-wrap: wrap;
    width: 100%;
}
.file[data-v-e349449c]{
        background-color: var(--lighter-grey-clr);
        border-radius: 10px;
        padding: 0.5em 1em;
}
.icon-name-container[data-v-e349449c]{
    display: flex;
    gap: 8px;
    font-family: Assistant;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    font-size: 18px;
}
button[data-v-e349449c]{
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}