.search-input[data-v-5733b3e1] {
  background: var(--lighter-grey-clr);
  border: 0;
  font-size: 16px;
  padding: 8px 12px;
  width: 260px;
  border-radius: 8px;
  height: 30px;
  outline: none;
}
.search-input[data-v-5733b3e1]:focus {
  outline: none;
}
.search-input[data-v-5733b3e1]::placeholder {
  color: var(--black-clr);
}
