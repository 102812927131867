.canvas-chat-btn {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    height: 1.5rem;
    margin-top: 0.25rem;
    color: black;
    width: 6rem;
}
.canvas-chat-btn.active-mode {
    border-radius: 5px;
    background: #e3e3e3;
    background: var(--Light-Mode-Light-Grey, #e3e3e3);
}
