.history-seperator[data-v-5ccbcf13] {
  height: 2px;
  width: 90%;
  margin: 0 auto;
  background-color: rgb(229, 234, 239);
}
.flex-upper-titles[data-v-5ccbcf13] {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.first-row[data-v-5ccbcf13] {
  display: flex;
  flex-direction: row;
  gap: 100px;
}
.close-btn[data-v-5ccbcf13] {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
}
.second-headline[data-v-5ccbcf13] {
  color: var(--text-clr);
  /* Text/P2 */
  padding-left: 20px;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  line-height: 20px;
}
.main-headline[data-v-5ccbcf13] {
  display: flex;
  align-items: center;
  gap: 5px;

  margin-bottom: 5px;
  color: var(--main-clr);
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.search-input[data-v-5ccbcf13] {
  display: flex;
  margin-right: auto;
  /* margin-top: 40px; */
  margin-left: auto;
  border-radius: 8px;
  border-radius: var(--8px, 8px);

  outline: 0;
  border: 0;
  background-color: var(--lighter-grey-clr);
  width: 280px;
  padding: 8px 12px;
  padding: var(--8px, 8px) var(--12px, 12px);

  justify-content: center;
  align-items: center;
  gap: 4px;
  gap: var(--4-px, 4px);
}
.ques-examples-page[data-v-5ccbcf13] {
  display: flex;
  gap: 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100vh - 250px);
}
.ques-examples-page .header[data-v-5ccbcf13] {
  display: flex;
}
.ques-examples-page .header .logo-container[data-v-5ccbcf13] {
  width: 87px;
  height: 87px;
  padding-top: 20px;
}
.ques-examples-page .answer-container[data-v-5ccbcf13] {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ques-examples-page .answer[data-v-5ccbcf13] {
  overflow: hidden;
  white-space: nowrap;
  height: 50px;
  /* max-width: 90%; */

  text-align: left;
  white-space: normal;
  color: var(--text-clr);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.ques-examples-page .current-editor[data-v-5ccbcf13] {
  font-family: Assistant;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  white-space: normal;
  color: var(--text-clr);
  vertical-align: middle;
}
.ques-examples-page .now[data-v-5ccbcf13] {
  width: 25%;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ques-examples-page .history-ques-date[data-v-5ccbcf13] {
  width: 25%;
  direction: rtl;
  text-align: left;
  font-size: 14px;
  font-family: Assistant;
}
.ques-examples-page .search-examples-input[data-v-5ccbcf13] {
  direction: rtl;
  color: var(--faded-blue-clr);
  font-size: 16px;
  padding: 5px 15px;
  margin-bottom: 30px;
  width: 214px;
  height: 35px;
  border-radius: 6px;
  background-color: var(--white-clr);
  font-family: 'openSans-regular';
  border: 0;
}
.ques-examples-page .search-examples-input[data-v-5ccbcf13]::placeholder {
  color: var(--faded-blue-clr);
}
.ques-examples-page .single-ques-ans[data-v-5ccbcf13] {
  width: 100%;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  transition: background-color 0.2s ease-in;
}
.ques-examples-page .active-log[data-v-5ccbcf13] {
  background-color: var(--main-undertone-clr) !important;
}
.ques-examples-page .active-session[data-v-5ccbcf13] {
  background-color: var(--lighter-grey-clr);
}
.ques-examples-page .single-ques-ans .back-home[data-v-5ccbcf13] {
  direction: rtl;
  text-decoration: underline;
  cursor: pointer;
}
.tool-kit[data-v-5ccbcf13] {
  background-color: var(--white-clr);
  position: absolute;
  right: 0%;
  height: 100%;
  top: 0%;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width: 315px;
  transition: all 0.2s ease-in;
}
.ques-examples-page .tag-in-history[data-v-5ccbcf13] {
  direction: rtl;
  margin-top: 6px;
  font-size: 14px;
}
.ques-examples-page .pagination-container[data-v-5ccbcf13] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 10px;
}
.ques-examples-page .pagination-container span[data-v-5ccbcf13] {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
.history-headline[data-v-5ccbcf13] {
    padding-left: 10px !important;
}
.ques-examples-page[data-v-5ccbcf13] {
    width: 315px;
}
.question[data-v-5ccbcf13] {
    font-size: 14px;
}
.answer[data-v-5ccbcf13] {
    font-size: 14px;
}
}
.restore-chat-flex[data-v-5ccbcf13] {
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--white-clr);
  border-top: 1px solid var(--lighter-grey-clr);
  bottom: 0%;
  width: 100%;
  height: 80px;
  z-index: 4;
}
.restore-btn[data-v-5ccbcf13] {
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5em 1em;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--main-clr);
  border: none;
  color: var(--text-clr-clr);
  font-family: Assistant;
}
.exact-date[data-v-5ccbcf13] {
  font-weight: 500;
  font-family: Assistant;
  margin-bottom: 10px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 30px;
  color: var(--text-clr);
}
.single-ques-ans[data-v-5ccbcf13]::before {
  content: '';
  width: 92%;
  display: block;
  position: absolute;
  border-bottom: 1px solid var(--lighter-grey-clr);
  bottom: 0%;
}
.flex-days[data-v-5ccbcf13] {
  width: 100%;
}
.img-psuedo-container[data-v-5ccbcf13] {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.files-counter[data-v-5ccbcf13] {
  padding: 0.03em 0.2em;
  display: block;
  position: absolute;
  z-index: 1;
  /* top: 50 %; */
  transform: translate(-50%, -70%);
  border-radius: 5px 5px 0px 5px;
  color: var(--text-clr);

  /* width: 17px;
    height: 14px; */
  text-align: center;
  background-color: var(--main-clr);
  /* Text/Hint */
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.file-type-img[data-v-5ccbcf13] {
  height: 24px;
  width: 24px;
}
.history-subheadline[data-v-5ccbcf13] {
  font-size: 16px;
  margin-top: 10px;
  padding-left: 20px;
  font-family: Assistant;
  text-align: left;
  align-items: flex-start;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--text-clr);
}
.history-headline[data-v-5ccbcf13] {
  font-size: 16px;
  margin-top: 10px;
  padding-left: 30px;
  font-family: Assistant;
  text-align: left;
  align-items: flex-start;
  font-weight: 500;
  color: var(--text-clr);
}
